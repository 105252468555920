@import "~@/assets/scss/global.scss";



















































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep {
    .el-dialog {
        max-height: 750px !important;
    }

    .el-dialog__body {
        max-height: 600px !important;
    }

    .el-dialog__body .el-form-item .el-input {
        max-width: inherit !important;
    }

    .el-checkbox__label {
        padding-left: 5px;
    }

    .el-radio-button {
        width: 20%;

        .el-radio-button__inner {
            width: 100%;
        }
    }

    .el-form-item__error {
        display: contents;
    }

    .el-table__row {
        font-size: 14px;

        .mark {
            &>div {
                color: #409EFF !important;
            }
        }

        &.hidden {
            display: none;
        }

        .el-table__cell {
            padding: 5px 0;
            vertical-align: middle;
        }
    }

    .el-tag {
        font-size: 14px;
        min-height: 30px;
        height: auto;
        line-height: 30px;
        margin: 2px !important;

        vertical-align: middle;
    }

    .el-select {
        width: 120px;
    }

    .el-range-editor.el-input__inner {
        padding: 2px 10px;
    }

    .el-date-editor .el-range-input {
        width: 72px;
    }
}

#special_spec {
    ::v-deep {
        .el-tag {
            min-height: 25px;
            line-height: 25px;
        }
    }
}

.input-new-name {
    width: 100px;

    ::v-deep {
        .el-input__inner {
            height: 35px !important;
            padding-left: 5px;
        }
    }
}

.button-new-name {
    line-height: 35px;
    padding-top: 0;
    padding-bottom: 0;
}

.colorSelect,
.sizeSelect {
    ::v-deep .el-tag {
        &:nth-last-child(2):first-child {
            max-width: 45px;
        }

        margin:1px 0px 1px 1px !important;
    }
}

.newPrice {
    width: 55px;
    margin-left: 3px;

    ::v-deep {
        .el-input__inner {
            text-align: center;
            padding: 0 5px;
        }
    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;

    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar {
    width: auto;
    max-height: 150px;
    display: block;
}
